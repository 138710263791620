import './layout.css'
import React from 'react'
import { navigate, Link } from 'gatsby'
import axios from 'axios'
import logo from '../images/encounter-dating.png'

export default class Forgot extends React.Component {
  state = {
    email: ``,
    loading: false
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleSubmit(e) {
    e.preventDefault()
    this.setState({loading: true})
    axios.post(`${process.env.API_BASE_URL}/api/reset-password`, {
      email: this.state.email
    })
    .then(res => {
      alert('Reset link has been sent to your email.')
      navigate('/login')
    })
    .catch(err =>{
      this.setState({loading: false})
      alert('Unable to locate email!')
    })
  }

  render() {
    return (
      <div className="content">
        <div className="has-text-centered">
          <Link to="/">
              <img alt="logo" src={logo} className="main-logo modified" />
          </Link>
        </div>
        <form method="post" onSubmit={e => this.handleSubmit(e)}  style={{ marginTop: '34px' }}>
          <div className="form-wrapper">
            <div className="field">
              <div className="control">
                <label>Email</label>
                <input className="input" name="email" type="text" onChange={e => this.handleChange(e)} />
              </div>
            </div>

            <br></br>
            <div className="fd-center">
              <button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-mobile ${this.state.loading ? 'is-loading' : ''}`} >Confirm</button>
              <div className="fd-central"><button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} >Confirm</button></div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
