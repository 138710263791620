import React from 'react'
import Forgot from '../components/forgot'
import Footer from '../components/footer'

const ForgotPage = () => (
  <div>
    <Forgot />
    <Footer />
  </div>
)

export default ForgotPage